@import '../../_scheme.sass';

.container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;

    .header {
        height: 200px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: 900px) {
            height: 150px; }

        .titles {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            .heading {
                font-size: 2.5em; }

            .subheading {
                font-size: 1.5em; } } }

    .content {
        height: calc(100% - 200px);

        @media (max-width: 900px) {
            height: calc(100% - 150px); }

        .todoContainer {
            width: 900px;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: top;
            align-items: center;
            text-align: center;

            @media (max-width: 900px) {
                width: 100%;
                max-width: 100%; }

            .noitems {
                font-size: 1.3em; }

            .todoList {
                height: 89%;
                width: 100%;
                max-width: 100%;
                overflow-y: scroll;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none; } }

            .todoEntry {
                height: 10%;
                width: 100%; } } } }


