@import '../../_scheme.sass';

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 10px 0;
    height: 40px;
    width: 100%;

    input {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        font-size: 1em;
        padding: 0 30px;
        -webkit-appearance: none;
        border-radius: 0; }

    button {
        height: 100%;
        width: 100px;
        border: none;
        outline: none;
        background: $primary;
        color: $text-primary;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.3);
        font-size: 1em;
        -webkit-transition: .3s;
        -moz-transition: .3s;
        transition: .3s;
        -webkit-appearance: none;
        border-radius: 0;

        &:hover {
            -webkit-transition: .3s;
            -moz-transition: .3s;
            transition: .3s;
            background: darken($primary, 5); } } }
