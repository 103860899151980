@import '../../_scheme.sass';

.container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;

        p {
            font-size: 3.5em; } }

    .selectionForm {
        width: 100%;
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 300px;
            height: 40px;

            border: none;
            background-color: $qaurtiary;
            color: $text-primary;
            font-size: 1em;

            -webkit-transition: .3s;
            -moz-transition: .3s;
            transition: .3s;

            &:hover {
                -webkit-transition: .3s;
                -moz-transition: .3s;
                transition: .3s;
                background-color: darken($qaurtiary, 5); } } }

    .entryForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: top;
        align-items: center;
        margin: 10px 0;

        input {
            width: 500px;
            height: 40px;
            margin: 5px 0;
            font-size: 1.2em;
            padding: 10px;

            border: none;
            border-radius: 0px;
            -webkit-appearance: none;

            @media (max-width: 500px) {
                width: 95%;
                border-radius: 0px; } }

        button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 500px;
            height: 40px;
            margin: 5px 0;

            border: none;
            background-color: $secondary;
            color: $text-primary;
            font-size: 1em;

            -webkit-transition: .3s;
            -moz-transition: .3s;
            transition: .3s;
            -webkit-appearance: none;

            @media (max-width: 500px) {
                width: 95%;
                border-radius: 0px; }

            &:hover {
                -webkit-transition: .3s;
                -moz-transition: .3s;
                transition: .3s;
                background-color: darken($secondary, 5); } } } }
