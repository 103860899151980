@import '../../_scheme.sass';

.container {
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    scrollbar-width: none;
    box-sizing: border-box;

    @media (max-width: 900px) {
        width: 100%;
        max-width: 100%; }

    &::-webkit-scrollbar {
        display: none; }

    .todo {
        width: 100%;
        max-width: 100%;
        min-height: 70px;
        padding: 20px 0px;
        margin-bottom: 10px;
        background: lighten($background, 10);
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        @media (max-width: 900px) {
            width: 100%;
            max-width: 100%;
            height: auto; }

        &:last-child {
            margin: 0; }

        .content {
            flex: 1;
            padding-left: 10px;

            display: flex;
            justify-content: left;
            align-items: center;
            text-align: left;

            p {
                word-break: break-all;
                overflow-wrap: anywhere; } }

        .time {
            width: 100px;
            height: 100%;
            color: $primary;

            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 900px) {
                width: 60px; } }

        .actions {
            width: 100px;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (max-width: 900px) {
                width: 60px; }

            .actionItem {
                width: 40px;
                height: 40px;
                font-size: 1.2em;
                color: $primary;

                display: flex;
                justify-content: center;
                align-items: center;
                -webkit-transition: .3s;
                -moz-transition: .3s;
                transition: .3s;
                border-radius: 30px;

                &:hover {
                    background: darken($background, 1);
                    color: $text-primary;

                    -webkit-transition: .3s;
                    -moz-transition: .3s;
                    transition: .3s; } } } } }
