@import './_scheme';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;

    font-family: 'Ubuntu', sans-serif;
    box-sizing: border-box; }

*:focus {
    outline: none;
    border: none; }

body, html {
    height: 100vh;
    max-height: -webkit-fill-available; }
