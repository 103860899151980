@import '../../_scheme.sass';

.container {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-width: -webkit-fill-available;
    max-height: -webkit-fill-available;
    overflow-x: hidden;

    .content {
        height: 100vh;
        max-height: -webkit-fill-available;
        width: 100%;

        background-color: $background;
        color: $text-primary;

        display: flex;
        flex-direction: columns;
        justify-content: center;
        align-items: center; } }
